
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class App extends Vue {
  title:string|null = null
  description:string|null = null
  message:string|null = null

  sendIt() {
    if (this.description) {
      this.message = null;
      const model = {
        title: this.title || 'N/C',
        description: this.description,
        category: 'None'
      }
      this.post(model).then((res) => {
        if (res.ok) {
          this.message = "Lettre anonyme déposée"
        } else {
          this.message = "Ooops, erreur"
        }
      })
    } else {
      this.message = "La description est obligatoire"
    }
  }


 async post(body: any):Promise<Response> {
  const init: RequestInit = {
    method: 'POST'
  }

  const jsonHeaders = new Headers({
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-Feebaco-AccessKey": "b1ne1WtERvcQW8G7lZi65nGi3UnxvdRs"
  })
  if (init.headers !== undefined) {
    (init.headers as Headers).forEach((entry) => {
      jsonHeaders.set(entry[0], entry[1])
    })
  }
  init.headers = jsonHeaders

  const projectId = "004f597b-ec60-484e-91ae-27b2e3a4bce3"
  const url = new URL(`https://feebaco.demo.codelutin.com/api/v1/projects/${projectId}/feedbacks`)
  init.body = JSON.stringify(body);
  const result = await fetch(url.toString(), init)
  return result
}

}
